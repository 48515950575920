import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "briefing", fluid: "", tag: "section" } },
        [
          _vm.showBriefingSecurityAlert
            ? _c(
                "div",
                {
                  staticClass: "pa-6 ma-3",
                  staticStyle: {
                    display: "flex",
                    gap: "10px",
                    color: "#1a7dff",
                    "border-radius": "10px",
                    border: "1px solid #1a7dff",
                  },
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block", "font-size": "1.2rem" },
                    },
                    [_vm._v(_vm._s(_vm.$t("banker_briefing_alert")))]
                  ),
                  _c(
                    "div",
                    [
                      _c(VIcon, { attrs: { color: "#1A7DFF" } }, [
                        _vm._v("mdi-alert-circle"),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.company
            ? _c("BriefingHeader", {
                attrs: {
                  Item: {
                    Name: _vm.company.name,
                    IntroVideo: _vm.company.briefing.introVideo,
                    Hero: _vm.company.briefing.hero,
                  },
                  SignedNDA: _vm.signed_nda,
                  GoToOptions: _vm.go_to_options(),
                },
                on: { go_to: _vm.scroll },
              })
            : _vm._e(),
          _c("AllocateBanner", {
            attrs: {
              Company: _vm.company,
              Value: _vm.value,
              Modal: _vm.openModal,
            },
          }),
          _vm.company != null
            ? _c("BriefingFinancial", {
                attrs: {
                  id: "financial",
                  company: _vm.company,
                  signed_nda: _vm.signed_nda,
                },
              })
            : _vm._e(),
          _vm.highlights.length > 0
            ? _c("BriefingHighlights", {
                attrs: { id: "highlights", Highlights: _vm.highlights },
              })
            : _vm._e(),
          !_vm.slidesLoading && _vm.slides.length > 0
            ? _c("BriefingCompanyPresentation", {
                attrs: { id: "presentation", Slides: _vm.slides },
                on: { fullscreen: _vm.presentation_fullscreen },
              })
            : _vm.slidesLoading
            ? _c(
                VRow,
                { attrs: { justify: "center", "no-gutters": "" } },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", size: "70", color: "primary" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("BriefingInvestmentConditions", {
            attrs: {
              id: "investment-conditions",
              BriefingId: _vm.company.briefing.id,
              PartnerB2bId: _vm.user.partnerB2bId,
            },
          }),
          _c("BriefingFiles", {
            attrs: {
              downloadUrl: "companyfile/download",
              id: "files",
              Files: _vm.files_filtered,
              SignedNDA: _vm.signed_nda,
            },
          }),
          _c("BriefingTeam", {
            attrs: { BriefingId: _vm.company.briefing.id, id: "team" },
          }),
          _c("BriefingLeadershipChat", {
            attrs: {
              BriefingId: _vm.company.briefing.id,
              MediaType: 2,
              Header: _vm.$t("leadership_chat"),
              id: "leadership-chat",
            },
          }),
          _c("BriefingAnalysis", {
            attrs: {
              id: "analysis",
              analysis: _vm.company.briefing.companyAnalysis,
            },
          }),
          _c("BriefingMarketNews", {
            attrs: { BriefingId: _vm.company.briefing.id, id: "market-news" },
          }),
          _c("BriefingMedia", {
            attrs: {
              BriefingId: _vm.company.briefing.id,
              MediaType: 0,
              Header: _vm.$t("media"),
              id: "media",
            },
          }),
          _c(VRow, { staticClass: "bottom-space" }, [
            _c("div", { staticClass: "mx-auto mt-4" }, [
              _c(
                "a",
                {
                  staticClass: "mr-4",
                  staticStyle: { "text-decoration": "underline" },
                  on: {
                    click: function ($event) {
                      return _vm.openTermsOfUseOrPrivacyPolicy("termsOfUse")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("terms_conditions")) + " ")]
              ),
              _c(
                "a",
                {
                  staticClass: "ml-4",
                  staticStyle: { "text-decoration": "underline" },
                  on: {
                    click: function ($event) {
                      return _vm.openTermsOfUseOrPrivacyPolicy("privacyPolicy")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("privacy_policy")) + " ")]
              ),
            ]),
          ]),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("FullscreenPresentationDialog", {
                    attrs: { id: "presentation_dialog", Slides: _vm.slides },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { margin: "0 auto", display: "block" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }